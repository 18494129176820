.process-container .tab,
.process-footer {
  &.hide {
    visibility: hidden;
    height: 20px;
  }
}

.process-container {
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;

  .top-bg {
    width: 100%;

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    padding-left: px2vw(20);
    padding-right: px2vw(20);

    .header-bg {
      z-index: 0;
      position: fixed;
      width: 100%;
    }

    .tab {
      border: 1px solid #EEEEEE;
      background-color: #F6F6F6;
      border-radius: 8px;
      display: flex;
      padding: 3px;
      justify-content: space-around;
      margin-bottom: 10px;

      .item {
        height: 33px;
        font-size: 16px;
        color: #575959;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s linear;
        flex: 1;
        border-radius: 6px;

        span {
          position: relative;
          z-index: 2;
          pointer-events: none;
        }

        &.active {
          color: #303233;
          font-weight: bold;
          filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.10));
          background-color: #FFFFFF;
        }
      }
    }

    .banner {
      width: px2vw(239);
      margin-bottom: 10px;
      display: none;

      &.show {
        display: block;
      }
    }

    .video {
      height: px2vw(228);
      box-sizing: border-box;
      // margin: 0 px2vw(12) 10px;
      margin: 0 0 10px;
      background-color: #000;
      border-radius: 8px;
      overflow: hidden;

      video {
        width: 100%;
        height: 100%;
      }
    }

    &>.content {
      img {
        width: 100%;
      }

      .sale {
        margin-top: 20px;

        img {
          margin-bottom: 27px;
        }
      }
    }

    .bottom {
      margin-bottom: 100px;

      .questions {
        background-color: #fff;
        margin: 10px 0 27px;
        box-sizing: border-box;
        border-radius: 8px;

        .title {
          width: 100%;
        }

        .content {
          padding-top: 12px;

          .qa-item {
            &:not(:last-child) {
              margin-bottom: 10px;
            }

            .qa-row {
              display: flex;
              justify-content: space-between;
              align-items: center;

              h3,
              i {
                pointer-events: none;
              }

              h3 {
                font-size: 14px;
                color: #666666;
                font-weight: 400;
                margin: 0;
              }

              .i::after {
                content: '+';
                font-style: normal;
                font-size: 12px;
              }

              &.active {
                .i::after {
                  content: '-';
                }
              }
            }

            .answer {
              position: relative;
              overflow: hidden;
              transition: all 0.2s ease-in-out;
              margin-top: 8px;

              p {
                font-size: 12px;
                color: #969798;
                line-height: 1.5;
              }
            }

            .qa-row h3,
            .answer {
              display: flex;
              align-items: flex-start;

              img {
                width: 16px;
                margin-right: px2vw(8);
              }

            }
          }
        }

        .view-all {
          padding: 18px 0 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: #999;

          .iconfont {
            font-size: 12px;
          }
        }
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #E3E3E3;
        font-size: 12px;

        img {
          width: px2vw(16);
          margin-right: px2vw(4);
        }
      }
    }
  }
}

.process-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 4px 12px 30px;
  z-index: 2;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 16px;
    color: #333;
    margin: 0;
    padding: 0;
    flex: 1;
    height: 40px;
    font-weight: bold;
    border-radius: 24px;

    img {
      width: px2vw(24);
    }

    &.online {
      background-color: #FFD149;
      margin-right: px2vw(12);
    }

    &.phone {
      border: 1px solid var(--grey-g-40, #E3E3E3);
    }
  }
}
