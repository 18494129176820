.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 px2vw(40);

  .logo-circle {
    margin-top: px2vw(50);
    width: px2vw(96);
  }

  .logo-text {
    margin-top: px2vw(10);
    width: px2vw(53);
  }

  .phone-input,
  .code-input {
    border-bottom: 1px solid #eee;
    padding: px2vw(5) 0;
    margin-top: px2vw(20);
    width: 100%;
    display: flex;
    align-items: center;

    input {
      border: none;
      outline: none;
      flex: 1;
      color: #333;
      font-size: px2vw(14);
      padding: px2vw(10) 0;
      margin-right: px2vw(10);
    }

    .get-code {
      padding: px2vw(10) 0;
      color: #FEBA07;
      font-size: px2vw(14);
    }
  }

  .login-button {
    width: 100%;
    margin-top: px2vw(35);
    display: flex;
    align-items: center;
    justify-content: center;
    height: px2vw(40);
    border-radius: px2vw(40);
    border: 1Px solid #FEBA07;
    background-color: #FEBA07;
    color: #fff;
    font-size: px2vw(14);
  }

  .prompt {
    font-size: px2vw(12);
    color: #999999;
    margin-top: px2vw(10);
  }

  .terms {
    margin-top: px2vw(50);
    font-size: px2vw(12);
    display: flex;
    align-items: center;
    color: #999;

    input {
      margin-right: px2vw(10);
      pointer-events: none;
    }

    // label {
    //   margin: 0;
    // }

    a {
      color: #feba07;
    }
  }
}
