.wangeditor-content-view p,
.wangeditor-content-view li {
  white-space: pre-wrap;
  /* 保留空格 */
}

.wangeditor-content-view blockquote {
  border-left: 8px solid #d0e5f2;
  padding: 10px 10px;
  margin: 10px 0;
  background-color: #f1f1f1;
}

.wangeditor-content-view code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
  border-radius: 3px;
}

.wangeditor-content-view pre>code {
  display: block;
  padding: 10px;
}

.wangeditor-content-view table {
  border-collapse: collapse;
}

.wangeditor-content-view td,
.wangeditor-content-view th {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}

.wangeditor-content-view th {
  background-color: #f1f1f1;
}

.wangeditor-content-view ul,
.wangeditor-content-view ol {
  padding-left: 20px;
}

.wangeditor-content-view input[type="checkbox"] {
  margin-right: 5px;
}
