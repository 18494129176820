// 自定义bootstrap的组件风格

.btn {
  font-size: 0.875rem;
  border-radius: 0.1rem;
}

.dropdown-menu {
  padding: 0.2rem 0;
  min-width: 5rem;
  border: none;
  border-radius: 0;

  .dropdown-item {
    padding: 0.5rem 0;
    text-align: center;
    font-size: 0.75rem;
  }
}

.alert {
  border: none;
  font-size: 0.75rem;
}

.pagination {
  font-size: 0.75rem;

  a {
    color: #FF8F00;
  }

  .page {
    display: inline-block;
    padding: 0 3px;

    a {
      border: none;
      box-shadow: none;
    }

    &.active {
      a {
        background: #FF8F00;
      }
    }
  }
}

.table {
  font-size: 0.75rem;

  th {
    background: #F6F6F6;
    border: none;
  }

  td {
    border: none;
  }

  td {
    vertical-align: middle;
  }
}
