// Place all the styles related to the home controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

.price {
  position: absolute;
  color: #FF8F00;
  background-color: #fff;
  padding: 2px 9px;
  border-radius: 25px;
  opacity: 0.9;

  >span {
    font-weight: bold;
    display: inline-block;
    font-size: 16px;

    &:first-letter {
      font-size: 12px;
    }
  }
}

.official-backflow-wrapper {
  .specialist-list-wrapper {
    .bottom-prompt {
      font-size: 12px;
      color: #ccc;
      text-align: center;
    }

    .specialist-list {
      margin-top: 30px;
      width: 280px;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 33.33% 33.33% 33.33%;

      .specialist {
        text-align: center;
        margin-bottom: 10px;
      }

      .realname {
        background: #C2EEFF;
        color: #000;
        line-height: 20px;
        display: inline-block;
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 14px;
      }

      figure {
        width: 54px;
        height: 54px;
        overflow: hidden;
        display: inline-block;
        border-radius: 50%;
        margin-bottom: -15px;

        img {
          width: 100%;
        }
      }
    }
  }

  @include mediaPc {
    .banner-top {
      margin-top: 66px;
      height: 279px;
      background-image: url("~images/official_website/backflow/banner.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      .content {
        max-width: 1200px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;

        .qrcode {
          $side: 120px;
          width: $side;
          height: $side;
          margin-left: 33px;
          margin-right: 19px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .title {
          color: #fff;

          h1 {
            font-size: 56px;
            font-weight: bold;
            margin-bottom: 10px;
          }

          h6 {
            font-size: 16px;
          }
        }
      }
    }

    .process-intro {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 33px;
      display: flex;
      flex-flow: column;
      align-items: center;
      overflow: hidden;

      .title-h2 {
        margin: 62px 0 20px;
      }

      .prompt {
        font-size: 14px;
        color: #333;
      }

      .process {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 79px 0 80px;

        .item {
          width: 233px;
          display: flex;
          flex-flow: column;
          align-items: center;

          img {
            width: 87px;
            margin-bottom: 24px;
          }

          .content {
            font-size: 16px;
            color: #333;

            span {
              color: #FF8F00;
            }
          }
        }

        .line {
          width: 126px;
          object-fit: contain;
          margin-bottom: 90px;
        }
      }

      .other-prompt {
        margin-bottom: 60px;
        width: 100%;
        padding: 20px 40px;
        background-color: #F9F9F9;
        border-radius: 8px;

        h4 {
          font-size: 16px;
          color: #333;
          margin-bottom: 15px;
          font-weight: bold;
        }

        p {
          font-size: 12px;
          color: #999;
          margin: 3px 0;
        }
      }
    }
  }

  @include mediaMobile {
    .process-intro {
      margin-top: 61.78px;
      background-image: linear-gradient(180deg, #2096FF, #50E3C2, #fff);
      padding-bottom: 20px;

      .bg {
        margin-bottom: 6px;
        position: relative;

        img {
          width: 100vw;
        }

        span {
          position: absolute;
          left: px2vw(44);
          top: px2vw(108);
          font-size: 14;
          color: #fff;
        }
      }

      .process-card {
        position: relative;
        margin: 0 px2vw(16);
        background-color: #fff;
        border-radius: 12px;

        &.one {
          padding: px2vw(40) px2vw(30);
          box-shadow: 0 10px 18px -12px #64C1F3;
          margin-bottom: 20px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:last-child) {
              margin-bottom: px2vw(60);
            }

            .image {
              position: relative;

              img:first-child {
                width: px2vw(56);
              }

              .line {
                position: absolute;
                width: 5px;
                left: 53%;
                transform: translateX(-50%);
                top: 105%
              }
            }

            .content {
              width: px2vw(196);
              font-size: 14px;
              color: #333;

              span {
                color: #FF8F00;
              }
            }
          }
        }

        &.two {
          border-radius: 12px;
          padding: 20px 16px 26px;
          box-shadow: 0px 10px 20px -12px #9BDEEF;

          .title-h2 {
            font-size: 16px;
            margin-bottom: 12px;
          }

          p {
            color: #666;
            font-size: 14px;
            margin: 5px 0;
          }
        }
      }

      .service-qrcode {
        display: flex;
        flex-flow: column;
        align-items: center;
        padding-top: px2vw(40);

        .image-qrcode {
          width: px2vw(180);
          margin-bottom: px2vw(10);
        }

        p {
          font-size: 12px;
          color: #999;
          margin: 0 0 px2vw(10) 0;
        }

        .qrcode-copy {
          background-color: #fff;
          padding: px2vw(5) px2vw(16);
          border-radius: 50px;
          display: flex;
          align-items: center;

          strong {
            margin-right: px2vw(10);
            font-size: 14px;
          }

          a {
            flex: 1;
            font-size: 12px;
            color: #1990FF;
            text-decoration: none;
            position: relative;

            &:hover,
            &:visited,
            &:active {
              color: #1990ff;
            }
          }
        }
      }
    }
  }

  .product-part {
    background-color: #F9F9F9;
    overflow: hidden;

    .carousel {
      .carousel-item {
        display: flex !important;
        justify-content: center;
        align-items: center;

        .product-content {
          position: relative;

          .price {
            position: absolute;
            left: 8px;
            bottom: 10px;
          }
        }
      }
    }

    @include mediaPc {
      .content {
        padding: 0 33px;

        .title-h2 {
          margin: 40px 0 60px;
        }

        .carousel {
          margin-bottom: 100px;

          .carousel-item {
            .product-content {
              border-radius: 20px;
              overflow: hidden;

              &:hover {
                .mini-code {
                  visibility: visible;
                  opacity: 1;
                }
              }

              .product-img {
                width: 259px;
                height: 259px;
                object-fit: cover;
              }

              .mini-code {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.5);
                transition: all 0.2s linear;

                div {
                  width: 150px;
                  height: 150px;
                  padding: 15px;
                  box-sizing: border-box;
                  border-radius: 50%;
                  margin-bottom: 13px;
                  overflow: hidden;
                  background-color: #fff;
                }

                img {
                  width: 100%;
                  height: 100%;
                }

                p {
                  margin: 0;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }
          }

          .slick-arrow {
            width: 48px;
            height: 48px;

            &.slick-prev {
              left: -55px;
            }

            &.slick-next {
              right: -55px;
            }

            &::before {
              font-size: 48px;
              color: #dddddd;
            }
          }
        }
      }
    }

    @include mediaMobile {
      .content {
        .title-h2 {
          margin: 30px 0;
        }

        .carousel {
          margin-bottom: 60px;

          .carousel-item {
            .product-content {
              margin: 0 20px;

              .product-img {
                width: px2vw(200);
                height: px2vw(220);
                border-radius: px2vw(20);
                object-fit: cover;
              }
            }
          }

          .slick-arrow {
            width: 48px;
            height: 48px;

            &.slick-prev {
              left: -55px;
            }

            &.slick-next {
              right: -55px;
            }

            &::before {
              font-size: 48px;
              color: #dddddd;
            }
          }
        }
      }
    }
  }
}

// 商城页面
.backflows-store-wrapper {
  @include mediaPc {
    background: #F8F8F8;
    margin-top: 66px;

    .backflow-pagination {
      margin: 0 auto;
      max-width: 1200px;
      padding: 10px 15px 30px;
    }

    .backflow-list {
      padding: 0;
      margin: 0 auto;
      max-width: 1200px;
      list-style: none;
      padding-top: 40px;

      .backflow-item {
        margin-bottom: 23px;
        background: #fff;
        border-radius: 16px;

        figure {
          border-radius: 16px;
          padding-bottom: 100%;
          margin: 0;
          overflow: hidden;
          position: relative;

          img {
            top: 0;
            position: absolute;
            width: 100%;
          }
        }

        .price {
          position: absolute;
          z-index: 1;
          left: 17px;
          bottom: 17px;
        }
      }

      .backflow-order-area {
        padding: 20px 0;
        text-align: center;
      }

      .backflow-order-button {
        color: #FF8F00;
        font-size: 14px;
        border: 1px solid;
        padding: 7px 28px;
        border-radius: 118px;
        display: inline-block;
      }
    }
  }
}


// 下单页面
.backflows-shopping-cart-wrapper {
  @include mediaPc {
    margin-top: 66px;

    .empty-area {
      text-align: center;
      padding-top: 30px;
      color: #ccc;
    }

    .order-qrcode-area {
      text-align: center;

      .qrcode-image-wrapper {
        position: relative;

        .mask {
          display: none;

          &.active {
            display: block;
            background: rgba(255, 255, 255, 0.8);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }

          .success-text {
            margin-top: 30px;
          }
        }
      }

      .tips {
        text-align: center;
        padding-top: 30px;
        color: #ccc;
      }
    }

    .backflows-shopping-cart {
      font-size: 0.75rem;
      padding: 0;
      margin: 0 auto;
      max-width: 1200px;
      list-style: none;
      padding: 40px 0;
    }

    .title {
      font-weight: bold;
      font-size: 0.75rem;
      padding: 10px 20px;
      background: #F6F6F6;
    }

    .addresses-area,
    .shipments-area {
      width: 50%;

      .radio-list {
        padding: 10px 20px;
        height: 150px;
        overflow: scroll;

        .radio-label {
          display: block;

          .radio-button {
            margin-right: 10px;
          }
        }
      }
    }

    .basic-information {
      display: flex;
    }

    .price-information {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
      font-size: 0.875rem;

      .wechat-area {
        span {
          font-size: 30px;
          margin-left: 15px;
          vertical-align: -4px;
        }
      }

      .price-area {
        flex: 1;

        .price-item {
          display: block;
          margin-left: auto;
          margin-right: 0;
          display: flex;
          max-width: 20%;
          justify-content: space-between;
          padding: 5px 0;

          .total-price {
            color: #FF8F00;
            font-weight: bold;
            font-size: 1rem;
          }
        }
      }
    }

    .button-area {
      font-weight: bold;
      text-align: right;

      .btn {
        color: #fff;
        background: #FF8F00;
      }
    }

    .basic-form {
      display: flex;
      justify-content: space-between;
    }

    .pending-orders-wrapper {
      background: #fff;
      width: 50%;

      .pending-orders {
        padding: 10px;
        height: 250px;
        overflow: scroll;
      }

      .btn {
        font-size: 0.5rem;
        color: #fff;
        background: #FF8F00;
        margin: 2px 0;

        &.btn-white {
          color: #000;
          background: #fff;
          border: 1px solid #eee;
        }
      }
    }

    .address-form-wrapper {
      background: #fff;
      width: 50%;
      border: 1px solid #F6F6F6;

      .address-form {
        padding: 10px 20px;
      }

      input,
      textarea {
        border: 1px solid #ccc;
        padding: 5px 10px;
      }

      select,
      input,
      textarea {
        margin-top: 10px;
      }

      .btn {
        background: #FF8F00;
        border: none;
        color: #fff;
      }
    }
  }
}


.special-client-page {
  background: #F5F5F5;

  .special-client-form-wrapper {
    padding-bottom: 16px;
    background: #fff;

    .uploader {
      padding: 10px 12px;
    }

    .file-upload-wrapper {
      border: 1px dashed #979797;
      border-radius: 8px;
      overflow: hidden;
      $defaultColor: #4daf7c;
      $height: 60px;

      position: relative;
      width: 100%;
      padding-bottom: 59%;
      height: 0;

      #blah {
        max-width: 100%;
        position: relative;
        display: none;
      }

      .button-area {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          width: 40px;
          height: 40px;
        }

        p {
          margin: 0;
          margin-top: 17px;
          color: #999;

          &.little {
            font-size: 12px;
          }
        }
      }

      input {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        margin: 0;
        padding: 0;
        display: block;
        cursor: pointer;
        width: 100%;
      }
    }

    .btn-wrapper {
      margin-top: 10;
      padding: 0 20px;

      .btn {
        height: 36px;
        width: 100%;
        color: #fff;
        background: #F9574B;
        border-radius: 18px;
      }
    }

    .tip-area {
      margin-top: 11px;
      font-size: 12px;
      background: #f8f8f8;
      margin: 11px 12px 0;
      padding: 11px 12px;

      .tag {
        color: #F9574B;
        background: #ffdfdd;
        padding: 1px 6px;
        margin-right: 7px;
      }

      .tip {
        color: #999;
      }

      .address {
        margin-top: 2px;
      }
    }
  }

  .backflows-area {
    margin-top: 10px;

    .nav {
      justify-content: center;

      &.nav-tabs {
        border-bottom: none;
        background: #fff;

        .nav-link {
          padding: 20px 40px 10px;
          padding-top: 20px;
          padding-bottom: 10px;
          border: none;
          line-height: 1.375;
          font-size: 16px;
          color: #666;

          &.active {
            font-weight: 900;
            color: #333;
            position: relative;

            &:after {
              content: '';
              display: block;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 20px;
              height: 4px;
              border-radius: 2px;
              position: absolute;
              background: #F9574B;
            }
          }
        }
      }
    }

    .backflows-grid-wrapper {
      min-height: 200px;
      padding: 10px;

      &:after {
        display: block;
        content: '';
        clear: both;
      }

      .backflow-row {
        margin: 0 -5px;
      }

      .backflow-item {
        border-radius: 4px;
        float: left;
        display: inline-block;
        width: 50%;
        margin-bottom: 10px;
        padding: 0 5px;
      }

      .backflow-image-wrapper {
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        height: 0;

        img {
          object-fit: cover;
          max-width: 100%;
        }

        .price {
          padding: 5px 10px;
          line-height: 1;
          left: 10px;
          bottom: 15px;
        }
      }
    }
  }

  .success-tips {
    padding: 0 12px;
    margin: 0;
    margin-top: 10px;
  }
}
