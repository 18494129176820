.qa-wrapper {
  .banner {
    width: 100%;

    @include mediaPc {
      margin-top: 66px;
    }

    @include mediaMobile {
      margin-top: 61.78px;
    }

    &.miniprogram {
      margin-top: 0;
    }
  }

  #qa-list {
    max-width: 1200px;
    margin: 40px auto;

    .empty-result {
      color: #333;
      font-size: 16px;
    }

    .search-area {
      margin-bottom: 20px;

      form {
        float: right;
        line-height: 3px;
      }

      &:after {
        display: table;
        content: '';
        clear: both;
      }

      input {
        outline: none;
      }

      .search-input {
        width: 334px;
        border: none;
        margin-right: -4px;
        ;
        background: #F5F5F5;
        color: #999;
        padding: 8px 17px;
        font-size: 14px;
        height: 48px;
        line-height: 14px;

        &::placeholder {
          color: lighten(#999, 20);
        }
      }

      .search-btn {
        background: #FEBA07;
        border: none;
        color: #fff;
        font-size: 16px;
        width: 66px;
        height: 48px;

        &:hover {
          background: darken(#FEBA07, 5);
        }

        &:active {
          background: darken(#FEBA07, 20);
        }
      }
    }

    .qa-item {
      h1 {
        font-weight: bold;
        font-size: 16px;
        color: #333;
      }

      p {
        padding: 4px;
        font-size: 14px;
        color: #666;
      }

      +.qa-item {
        margin-top: 40px;
      }
    }
  }

  #qa-list-mobile {
    padding: 10px 15px 60px;
    background: #f5f5f5;
    min-height: 100vh;

    .search-area {
      margin-bottom: 10px;
      position: relative;

      .search-input {
        width: 100%;
        border: none;
        padding: 8px 12px 8px 30px;
        font-size: 14px;
        border-radius: 15px;
        height: 30px;
        line-height: 14px;
        outline: none;

        +.search-icon {
          width: 16px;
          height: 16px;
          position: absolute;
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .empty-result {
      color: #333;
      font-size: 16px;
    }

    .qa-item {
      border-radius: 4px;
      background: #fff;

      h1 {
        font-weight: bold;
        margin-bottom: 0;
        padding: 18px 10px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          transform: rotate(-180deg);
          transition: 0.3s transform ease-in;
          width: 12px;
        }

        &.collapsed {
          img {
            transform: rotate(0);
          }
        }
      }

      .content {
        color: #666;
        font-size: 14px;
        padding: 0 10px 10px;
      }

      +.qa-item {
        margin-top: 10px;
      }
    }
  }
}
