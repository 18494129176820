.login-form-wrapper {
  max-width: 580px;
  margin: 100px auto;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);;
  border-radius: 20px;
  padding: 40px 60px;

  > h1 {
    color: #222;
    text-align: center;
    font-size: 24px;
  }

  .login-form {
    margin-top: 30px;
  }

  .message-code-field {
    position: relative;
  }

  .field {
    input {
      outline: none;
      width: 100%;
      border: none;
      border-bottom: 1px solid #ccc;
      padding: 1rem 0;
      font-size: 1rem;

      &::placeholder {
        color: #ccc;
      }
    }

    +.field {
      margin-top: 24px;
    }
  }

  .check-box-wrapper {
    display: inline-block;

    #accept {
      vertical-align: -2px;
    }

    label {
      font-size: 0.75rem;
      color: #999;

      a {
        color: #000;
      }
    }
  }

  .btn-wrapper {
    margin-top: 70px;

    .login-btn {
      border: none;
      color: #fff;
      font-weight: blod;
      font-size: 1rem;
      padding: 20px;
      display: block;
      width: 100%;
      background: #FEBA07;
      border-radius: 7px;

      &:disabled {
        background: lighten(#FEBA07, 30%);
      }
    }
  }

  .fetch-code {
    position: absolute;
    top: 50%;
    right: 0;
    color: #ccc;
    transform: translateY(-50%);
    border: none;
    background: no-repeat;

    &.pointer {
      cursor: pointer;
    }
  }
}
