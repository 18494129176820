.dy-entry-page {
  height: 100vh;
  background-image: url("~images/dy_entry/bg.jpg");
  background-size: contain;
  position: relative;

  .test {
    word-break: break-all;
  }

  a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 650px;
    background-color: #FEBA07;
    height: 50px;
    padding: 0 50px;
    white-space: nowrap;
    color: #fff !important;
    align-items: center;
    border-radius: 100px;
    display: none;
  }
}
