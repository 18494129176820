.article-detail-wrapper {
  .article-header {
    border-bottom: 1px solid #eee;

    .title {
      color: #333;
      font-weight: bold;
    }

    .info {
      display: flex;
      color: #999;
      font-size: 12px;
    }
  }

  .article-content {
    padding: 23px 30px 60px;

    section {
      word-wrap: break-word;
    }

    .download-card {
      background: #F9F9F9;
      border-radius: 12px;

      .introduction {
        display: flex;
        align-items: center;

        span {
          color: #999;
          font-size: 14px;
        }
      }

      .download-button,
      .download-qrcode {

        .label {
          font-size: 12px;
          color: #333;
        }
      }

      .download-qrcode {
        .content {

          .android,
          .ios {
            display: inline-block;
            background-color: #fff;
            border-radius: 4px;

            .text {
              text-align: center;
              font-size: 12px;
              color: #999;
            }
          }
        }
      }
    }
  }

  @include mediaPc {
    min-height: calc(100vh - 318px);
    background-color: hsl(0, 0%, 97%);
    padding: 92px 0 80px;
    box-sizing: border-box;

    .container {
      padding: 0;
      max-width: 1200px;
      margin: 0 auto;
      background-color: #fff;

      .article-header {
        padding: 30px 30px 20px;

        .title {
          font-size: 18px;
          margin-bottom: 20px;
        }

        .info {
          div {
            margin-right: 60px;
          }
        }
      }

      .article-content {
        padding: 23px 30px 60px;

        section {

          img,
          video {
            max-width: 100%;
          }
        }

        .download-card {
          margin-top: 40px;
          padding: 18px 21px 36px;

          .introduction {
            margin-bottom: 22px;

            .logo {
              width: 72px;
              margin-right: 11px;
            }

            span {
              flex: 1;
            }
          }

          .download-button,
          .download-qrcode {
            display: inline-flex;

            .label {
              margin-top: 14px;
            }

            .content {
              margin-left: 11px;
            }
          }

          .download-button {
            margin-right: 249px;

            .content {
              img {
                cursor: pointer;
                display: block;
                width: 166px;
                margin-bottom: 12px;
              }
            }
          }

          .download-qrcode {
            .content {

              .android,
              .ios {
                img {
                  width: 115px;
                }

                .text {
                  padding: 1px 0 5px;
                }
              }

              .android {
                margin-right: 80px;
              }
            }
          }
        }
      }
    }
  }

  @include mediaMobile {
    margin-top: 61.78px;

    .container {
      padding: 0;

      .article-header {
        padding: px2vw(18) px2vw(12) px2vw(20);

        .title {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .info {
          div {
            margin-right: px2vw(30);
          }
        }
      }

      .article-content {
        padding: px2vw(19) px2vw(12) px2vw(60);

        section {

          img,
          video {
            width: 100% !important;
            height: initial !important;
            object-fit: cover;
            display: block !important;
          }
        }

        .download-card {
          margin-top: 20px;
          padding: px2vw(20) px2vw(18);

          .introduction {
            flex-direction: column;
            justify-content: center;
            margin-bottom: 30px;

            .logo {
              width: 54px;
              margin-bottom: 18px;
            }
          }

          .download-button,
          .download-qrcode {

            text-align: center;

            .label {
              margin-bottom: 1px;
            }

            .content {
              display: flex;
              justify-content: center;
            }
          }

          .download-button {
            margin-bottom: 21px;

            .content {
              img {
                display: block;
                width: px2vw(166);
              }
            }
          }

          .download-qrcode {
            .content {
              margin-top: 10px;

              .android,
              .ios {
                display: inline-block;
                background-color: #fff;
                border-radius: 4px;

                img {
                  width: px2vw(136);
                }

                .text {
                  padding: 1px 0 px2vw(9);
                }
              }

              .android {
                margin-right: px2vw(27);
              }
            }
          }
        }
      }
    }
  }
}
