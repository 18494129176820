body {
  font-family: 'Microsoft Yahei', 'PingFang SC', sans-serif;
  padding: 0;
  margin: 0;
}

// from third-part
@import 'bootstrap/dist/css/bootstrap.css';

// customization

@import 'stylesheets/custom_bootstrap.scss';
@import 'stylesheets/mixin.scss';
@import 'stylesheets/backflows.scss';
@import 'stylesheets/applyments.scss';
@import 'stylesheets/official_website.scss';
@import 'stylesheets/home.scss';
@import 'stylesheets/about.scss';
@import 'stylesheets/qa.scss';
@import 'stylesheets/terms.scss';
@import 'stylesheets/sessions.scss';
@import 'stylesheets/download.scss';
@import 'stylesheets/post.scss';
@import 'stylesheets/pagination.scss';
@import 'stylesheets/wangeditor.scss';
@import 'stylesheets/welfare.scss';
@import 'stylesheets/login.scss';
@import 'stylesheets/rights.scss';
@import 'stylesheets/ios-links.scss';
@import 'stylesheets/process.scss';
@import 'stylesheets/explain.scss';
@import 'stylesheets/posts_list.scss';
@import 'stylesheets/dy_entry.scss';
@import 'stylesheets/door-to-door.scss';

@import '//at.alicdn.com/t/c/font_3428554_f1drwnzlon.css';

[data-controller='backflow'],
[data-controller='about'],
[data-controller='questions'],
[data-controller='posts_list'],
[data-controller='posts'] {
  .header {
    background-color: #fff;
    box-shadow: 0 -10px 15px 0 rgba(0, 0, 0, 0.7);

    .nav-link {
      color: #222 !important;
    }
  }
}

[data-controller='terms'],
[data-controller='download'],
[data-controller='welfare'],
[data-controller='door_to_door'],
[data-controller='login'],
[data-controller='process'],
[data-controller='explain'],
[data-controller='dy_entry'] {

  .header,
  .backtotop-button,
  .footer,
  .record,
  &~.header,
  &~.backtotop-button,
  &~.footer,
  &~.record {
    display: none;
  }
}
