.official-website-wrapper {
  min-height: 100vh;

  .title-h2 {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    $title_margin: 20px;

    &::before,
    &::after {
      content: '';
      background-size: 100% 100%;
    }

    @include mediaPc {
      font-size: 28px;
      $title_margin: 20px;

      &::before,
      &::after {
        width: 28px;
        height: 24px;
      }

      &.type2 {
        &::before {
          background-image: url("~images/official_website/backflow/title.png");
          margin-right: $title_margin;
        }

        &::after {
          background-image: url("~images/official_website/backflow/title.png");
          transform: rotate(180deg);
          transform-origin: 50% 50%;
          margin-left: $title_margin;
        }

        &::before,
        &::after {
          width: 27px;
          height: 18px;
        }
      }
    }

    @include mediaMobile {
      font-size: 20px;
      $title_margin: 12px;

      &::before,
      &::after {
        width: 18px;
        height: 18px;
      }

      &.type2 {
        &::before {
          background-image: url("~images/official_website/backflow/title.png");
          margin-right: $title_margin;
        }

        &::after {
          background-image: url("~images/official_website/backflow/title.png");
          transform: rotate(180deg);
          transform-origin: 50% 50%;
          margin-left: $title_margin;
        }

        &::before,
        &::after {
          width: 18px;
          height: 12px;
        }
      }
    }
  }

  .header {
    width: 100%;
    position: fixed;
    top: 0;
    transition: all 0.1s linear;
    z-index: 10;

    &.scroll,
    &.drop {
      background-color: #fff;
      box-shadow: 0 -10px 15px 0 rgba(0, 0, 0, 0.7);

      .nav-link {
        color: #222 !important;
      }
    }

    .navbar {
      box-sizing: border-box;
      justify-content: space-between;

      .collapse.navbar-collapse {
        flex-grow: initial;
      }

      .navbar-toggler {
        border: none;
        padding: 0.25rem 0.1rem;
        transform: translateX(2px);

        .navbar-toggler-icon {
          width: 1.4rem;
          height: 1.4rem;
        }

        &:active {
          border: none;
          outline: none;
        }
      }

      .navbar-nav {
        .nav-item {
          $padding: 20px;
          padding-left: $padding;
          padding-right: $padding;
          cursor: pointer;

          .nav-link {
            padding: 0.5rem 0;
            color: #222 !important;
          }

          .nav-link.active {
            font-weight: bold;
            position: relative;

            @media screen and (min-width:993px) {
              &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                bottom: -2px;
                border-bottom: 2px solid #FEBA07;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    background-color: #404345;
    color: #fff;

    .footer-pc,
    .footer-mobile {
      .hrefs {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
      }

      a {
        color: #fff;
      }

      .qrcode-part {
        display: flex;


        .item {
          display: flex;
          flex-flow: column;
          align-items: center;

          img {
            border-radius: 6px;
            overflow: hidden;
          }

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .record {
    background-color: #212426;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 12px;
      color: #666666;
      text-align: center;
    }

    @include mediaPc {
      height: 40px;
    }

    @include mediaMobile {
      height: 48px;
    }
  }

  .backtotop-button {
    visibility: hidden;
    opacity: 0;
    display: flex;
    bottom: 200px;
    position: fixed;
    right: 0;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: #EEEEEE;
    box-shadow: -3px 1px 10px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.2s linear;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    span {
      font-size: 12px;
      line-height: 12px;
    }

    &:hover {
      cursor: pointer;
    }

    @include mediaPc {
      width: 60px;
      height: 60px;

      img {
        width: 24px;
        margin-bottom: 5px;
      }
    }

    @include mediaMobile {
      $side: px2vw(50);
      width: $side;
      height: $side;

      img {
        width: px2vw(18);
        margin-bottom: px2vw(4);
      }
    }
  }

  @include mediaMobile {
    .header {
      background-color: #fff;

      .navbar {
        .header-logo {
          width: 85px;
        }
      }
    }

    .footer-mobile {
      padding: 30px 26px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .footer-download {
        width: 100%;
        display: flex;
        justify-content: space-around;

        img {
          width: px2vw(148);
        }

        margin-bottom: 40px;
      }

      .qrcode-part {
        width: 100%;
        justify-content: space-around;
        margin-bottom: 40px;

        .item {
          img {
            width: px2vw(80);
            margin-bottom: 10px;
          }
        }
      }

      .logo,
      h3,
      p {
        align-self: flex-start;

      }

      .logo {
        width: px2vw(95);
        margin-bottom: 12px;
      }

      h3 {
        font-size: 16px;
        margin-bottom: 20px;
      }

      p {
        font-size: 12px;
        margin: 5px 0;
      }
    }
  }

  @include mediaPc {
    .pc {
      max-width: 1200px;
      margin: 0 auto;
    }

    .header {
      .navbar {
        padding: 0;

        .navbar-brand {
          background-color: #fff;
          border-radius: 0 0 12px 12px;
          padding: 16px 17px;

          .header-logo {
            width: 95px;
          }
        }

        .navbar-nav {
          .nav-link {
            font-size: 14px;
          }
        }
      }
    }

    .footer-pc {
      display: flex;
      justify-content: space-between;
      padding: 60px 0;

      .left-part {
        display: flex;

        p {
          font-size: 12px;
          margin: 4px 0;
        }

        .info-part1 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-right: 70px;

          img {
            width: 95px;
          }

          h3 {
            font-size: 16px;
            margin: 19px 0;
          }
        }

        .info-part2 {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .footer-download {
            display: flex;
            flex-direction: column;

            img {
              width: 148px;
              cursor: pointer;
              margin-bottom: 10px;
            }
          }

          .hrefs {
            width: 226px;
            margin-bottom: 70px;
          }
        }
      }

      .qrcode-part {
        .item {
          &:not(:last-child) {
            margin-right: 60px;
          }

          img {
            width: 120px;
            margin-bottom: 20px;
          }

        }
      }
    }
  }

  @media screen and (min-width:993px) and (max-width:1201px) {

    .header,
    .footer {
      padding: 0 20px;
    }
  }

  &.webview {

    .header,
    .footer,
    .record {
      display: none;
    }

    &.webview {

      // 回流页面
      .process-intro {
        margin-top: 0;
      }

      // 条款页面
      .qa-wrapper .banner {
        margin-top: 0;
      }
    }
  }
}
