.ios-links-container {
  min-height: 100vh;
  padding: 16px;

  .ios-link-item {
    display: block;
    width: fit-content;

    &:not(:first-child) {
      margin-top: 28px;
    }
  }
}
