.door-to-door-container {
  position: relative;

  .bg {
    width: 100%;

    &.bg3,
    &.bg4 {
      display: none;
    }
  }

  .btn-row {
    width: 100%;
    left: 0;
    // position: fixed;
    display: flex;
    padding: px2vw(8) px2vw(12) px2vw(40);
    background: #FFF;
    box-shadow: 0px -1px 0px 0px #EEE;

    .button {
      flex: 1;
      height: px2vw(40);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: px2vw(16);
      color: #303233;
      box-sizing: border-box;

      &:not(:last-child) {
        margin-right: px2vw(8);
      }

      &.gray {
        border-radius: 99px;
        border: 1px solid var(--Grey-G40, #E3E3E3);
        display: none;
      }

      &.yellow {
        border-radius: 99px;
        background: var(--Yellow-Y300, #FFD149);
        font-weight: bold;
      }
    }
  }
}
