@import '~stylesheets/mixin.scss';

.official-home-wrapper {
  .tap-transition {
    transform: scale(1);
    transition: all 0.3s linear;
    cursor: pointer;

    &:active {
      transform: scale(0.96);
    }
  }

  @include mediaMobile {
    margin-top: 61.78px;

    .banner-wrapper-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        margin-top: px2vw(30);
        color: #06C755;
        font-family: "Alibaba PuHuiTi 3.0";
        font-size: px2vw(40);
        font-style: normal;
        font-weight: 700;
        line-height: px2vw(47);
      }

      h2,
      .tags,
      .code {
        margin-top: px2vw(12);
      }

      h2 {
        color: #222;
        font-family: "Alibaba PuHuiTi 3.0";
        font-size: px2vw(32);
        font-style: normal;
        font-weight: 700;
        line-height: px2vw(36);
      }

      .tags {
        display: flex;
        align-items: center;
        color: #999;
        font-family: "Alibaba PuHuiTi 3.0";
        font-size: px2vw(9);
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        div {
          padding: px2vw(2) px2vw(6);
          border-radius: 2px;
          border: 1px solid #EEE;
          margin-left: px2vw(4);
        }
      }

      .code {
        display: flex;
        align-items: center;
        .ios-download,
        .android-download,
        .weapp {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 6px;
          background: #FFF;
          box-shadow: 0px 0px px2vw(6) 0px rgba(0, 0, 0, 0.08);
          padding: px2vw(10);

          &:not(:last-child) {
            margin-right: px2vw(12);
          }

          &>img {
            width: px2vw(69);
            height: px2vw(69);
          }

          .label {
            margin-top: px2vw(6);
            display: flex;
            align-items: center;
            font-size: px2vw(8);

            img {
              width: px2vw(8);
            }
          }
        }
      }

      .bg {
        width: px2vw(270);
        height: px2vw(270);
        margin-top: px2vw(20);
        transform: translateX(px2vw(-20));
      }
    }

    .wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: px2vw(35) px2vw(20) px2vw(30);

      &.white {
        background-color: #fff;
      }

      &.gray {
        background-color: #F8FDFB;
      }

      &>.prompt {
        margin-top: px2vw(20);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 12px;
          color: #666;
          padding: 0;
          margin: 0;
        }
      }

      &>.title {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        text-align: center;
      }

      &>.container {
        padding: 0;
      }

      &>.button {
        height: px2vw(40);
        padding: 0 px2vw(28);
        font-size: 14px;
        color: #333;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #333333;
        border-radius: px2vw(20);
      }
    }

    #intro {
      .content {
        $border: 1px solid #EEEEEE;
        margin-top: px2vw(20);
        display: flex;
        flex-flow: column;
        align-items: center;
        border: $border;
        border-radius: 16px;

        .intro-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          padding: 0 px2vw(20);

          &:not(:last-child) {
            border-bottom: $border;
          }

          .intro-title {
            margin: px2vw(40) 0 px2vw(20);
            width: 100%;
            font-weight: bold;
            font-size: 18px;
            color: #333;
          }

          .intro-content {
            line-height: 2;

            p {
              margin: 0;
              padding: 0;
              font-size: 14px;
              color: #666;
            }
          }

          .intro-img {
            width: 100%;
            margin: px2vw(20) 0;
          }

          &:not(:first-child) {
            .intro-img {
              width: 50%;
            }
          }
        }
      }
    }

    #process {
      .container {
        margin: px2vw(30) 0 px2vw(60);
        display: flex;
        align-items: center;
        justify-content: space-around;

        .item {
          text-align: center;
          position: relative;
          display: flex;
          justify-content: center;

          .process-img {
            width: px2vw(54);
            margin: 0 px2vw(9);
          }

          span {
            position: absolute;
            font-size: 12px;
            color: #333;
            bottom: px2vw(-30);
            white-space: nowrap;
          }
        }

        .line {
          display: inline-block;
          width: px2vw(28);
          height: 1px;
          background-color: #6abd6f;
        }
      }
    }

    #case {
      .container {
        margin-top: px2vw(14);
        display: flex;
        flex-flow: column;
        align-items: center;

        .count-wrapper {
          display: flex;
          align-items: center;

          .left,
          .right {
            width: px2vw(80);
          }

          .left {
            margin-right: px2vw(8);
          }

          .right {
            margin-left: px2vw(8);
            transform: rotate(180deg);
          }

          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: px2vw(30);
            width: px2vw(22);
            border-radius: px2vw(4);
            background-color: #FF8F00;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            margin: 0 3px;
          }
        }

        .count-prompt {
          margin-top: px2vw(6);
          font-size: 12px;
          color: #666;
        }

        .case-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: px2vw(20);

          .item {
            width: calc((100% - #{px2vw(10)}) / 2);
            height: px2vw(180);
            position: relative;
            overflow: hidden;
            border-radius: px2vw(12);
            margin-bottom: px2vw(10);

            &:not(:nth-child(2n)) {
              margin-right: px2vw(10);
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
            }

            .case-price {
              position: absolute;
              bottom: px2vw(10);
              left: px2vw(8);
              z-index: 2;
              background-color: rgba($color: #fff, $alpha: 0.9);
              padding: 0 px2vw(14) px2vw(6);
              height: px2vw(32);
              display: flex;
              align-items: flex-end;
              border-radius: px2vw(16);
              color: #FF8F00;
              font-weight: bold;

              span:nth-child(1) {
                font-size: 12px;
                line-height: 1.1;
              }

              span:nth-child(2) {
                font-size: 16px;
                line-height: 1;
              }
            }
          }
        }
      }

      &>.button {
        margin-top: 20px;
      }
    }

    #company {
      .container {
        margin-top: px2vw(20);

        .show-mobile {
          .item {
            overflow: hidden;

            &:not(:last-child) {
              margin-bottom: px2vw(30);
            }

            &:not(:first-child) {
              .img {
                filter: drop-shadow(0 1px 3px rgba(57, 190, 117, 0.1));
              }
            }

            .img {
              width: px2vw(60);
              float: left;
              margin-right: px2vw(10);

              img {
                width: 100%;
              }
            }

            .content {
              color: #333;

              .row1 {
                font-size: 15px;
                font-weight: bold;
                margin-bottom: px2vw(8);
                white-space: nowrap;
              }

              .row2 {
                font-size: 12px;
                line-height: 2;
              }
            }
          }
        }
      }
    }

    #news {
      padding-left: 0;
      padding-right: 0;

      .container {
        margin-top: 20px;

        .list {
          .item {
            display: flex;
            align-items: center;
            padding: px2vw(18) px2vw(12);
            flex-direction: row-reverse;

            .cover {
              display: flex;
              align-items: center;
              justify-content: center;
              width: px2vw(114);
              height: px2vw(88);
              overflow: hidden;
              border-radius: 4px;
              margin-left: px2vw(20);
              background-color: #eee;

              img {
                max-width: 100%;
              }

              .temp {
                width: px2vw(97);
              }
            }

            .content {
              height: px2vw(88);
              flex: 1;
              display: flex;
              overflow: hidden;
              flex-direction: column;
              justify-content: space-between;

              .top {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                  color: #333;
                  font-size: 16px;
                  font-weight: bold;
                }

                .date {
                  color: #999;
                  font-size: 12px;
                }
              }
            }

            &:not(:last-child) {
              border-bottom: 1px #eee solid;
            }
          }
        }
      }
    }

    .partner-part {
      padding: 40px 0 60px;

      h2 {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: bold;
      }

      .partner-content {
        padding: 0 px2vw(13);

        .item {
          img {
            width: px2vw(110);
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  @include mediaPc {
    overflow-x: hidden;

    .banner-wrapper-pc {
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
      max-width: 1200px;
      margin: 72px auto 0;

      .left {
        h1 {
          color: #06C755;
          font-family: "Alibaba PuHuiTi 3.0";
          font-size: 80px;
          font-weight: 700;
          line-height: 94px;
        }

        h2,
        .tags {
          margin-top: 24px;
        }

        h2 {
          color: #222;
          font-family: "Alibaba PuHuiTi 3.0";
          font-size: 64px;
          font-style: normal;
          font-weight: 700;
          line-height: 72px;
        }

        .tags {
          display: flex;
          align-items: center;
          color: #999;
          font-family: "Alibaba PuHuiTi 3.0";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          div {
            border-radius: 4px;
            border: 1px solid #EEE;
            padding: 4px 12px;
            margin-left: 8px;
          }
        }

        .code {
          display: flex;
          align-items: center;
          margin-top: 33px;

          .ios-download,
          .android-download,
          .weapp {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 12px;
            background: #FFF;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
            padding: 20px;

            &:not(:last-child) {
              margin-right: 24px;
            }

            &>img {
              width: 138px;
              height: 138px;
            }

            .label {
              margin-top: 12px;
              display: flex;
              align-items: center;
              font-size: 16px;

              img {
                width: 16px;
              }
            }
          }
        }
      }

      .right {
        img {
          width: 540px;
          height: 540px;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;

      &.white {
        padding: 60px 0 80px;
        background-color: #fff;
      }

      &.gray {
        padding: 60px 0;
        background-color: #F8FDFB;
      }

      &>.prompt {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          font-size: 16px;
          color: #666;
          padding: 0;
          margin: 0;
        }
      }

      &>.title {
        font-size: 36px;
        font-weight: bold;
        color: #333;
        text-align: center;
      }

      &>.container {
        width: 1200px;
        margin: 0 auto;
      }

      &>.button {
        height: 50px;
        padding: 0 56px;
        font-size: 14px;
        color: #333;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #333333;
        border-radius: 30px;
      }
    }

    #intro {
      .content {
        $border: 1px solid #EEEEEE;
        margin-top: 39px;
        display: flex;
        border: $border;
        border-radius: 16px;

        .intro-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          .intro-title {
            margin: 66px 0 41px;
            width: 100%;
            font-weight: bold;
            font-size: 24px;
            color: #333;
          }

          .intro-content {
            line-height: 2;
            line-height: 2;

            p {
              margin: 0;
              padding: 0;
              font-size: 14px;
              color: #666;
            }
          }
        }

        .left {
          flex: 1;
          border-right: $border;
          box-sizing: border-box;

          .intro-title {
            text-align: center;
          }

          .intro-img {
            width: 245px;
            margin: 39px 0;
          }
        }

        .right {
          flex: 2;
          display: flex;
          flex-direction: column;

          .top,
          .bottom {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .intro-container {
              margin-left: 54px;
              align-self: flex-start;
            }

            .intro-img {
              width: 180px;
              margin-right: 54px;
            }
          }

          .top {
            border-bottom: $border;
          }
        }
      }
    }

    #process {
      .container {
        margin: 40px 0 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .item {
          text-align: center;
          position: relative;
          display: flex;
          justify-content: center;

          .process-img {
            width: 100px;
            margin: 0 18px;
          }

          span {
            position: absolute;
            font-size: 16px;
            color: #333;
            bottom: -40px;
          }
        }

        .line {
          width: 180px;
        }
      }
    }

    #case {
      .container {
        margin-top: 20px;
        display: flex;
        flex-flow: column;
        align-items: center;

        .count-wrapper {
          display: flex;
          align-items: center;

          .left,
          .right {
            width: 180px;
          }

          .left {
            margin-right: 10px;
          }

          .right {
            margin-left: 10px;
            transform: rotate(180deg);
          }

          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 46px;
            width: 34px;
            border-radius: 5px;
            background-color: #FF8F00;
            color: #fff;
            font-size: 40px;
            font-weight: bold;
            margin: 0 3px;
          }
        }

        .count-prompt {
          margin-top: 8px;
          font-size: 16px;
          color: #666;
        }

        .case-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: 30px;

          .item {
            width: calc((100% - 60px) / 4);
            height: 285px;
            position: relative;
            overflow: hidden;
            border-radius: 16px;
            margin-bottom: 20px;

            &:not(:nth-child(4n)) {
              margin-right: 20px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
              position: relative;
              z-index: 1;
            }

            .case-price {
              position: absolute;
              bottom: 10px;
              left: 8px;
              z-index: 2;
              background-color: rgba($color: #fff, $alpha: 0.9);
              padding: 0 19px 8px;
              height: 34px;
              display: flex;
              align-items: flex-end;
              border-radius: 18px;
              color: #FF8F00;
              font-weight: bold;

              span:nth-child(1) {
                font-size: 12px;
                line-height: 1.2;
              }

              span:nth-child(2) {
                font-size: 16px;
                line-height: 1;
              }
            }
          }
        }
      }

      &>.button {
        margin-top: 20px;
      }
    }

    #company {
      .container {
        margin-top: 40px;

        .show-pc {
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 53px;

            .img {
              width: 120px;
              margin-right: 40px;

              img {
                width: 100%;
              }
            }

            .content {
              flex: 1;
              color: #333;

              .row1 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 22px;
              }

              .row2 {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    #news {
      .container {
        margin-top: 20px;

        .list {
          .item {
            text-decoration: none;
            display: flex;
            align-items: center;
            padding: 20px;
            transition: all 0.2s linear;

            .cover {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 172px;
              height: 108px;
              border-radius: 4px;
              margin-right: 20px;
              background-color: #eee;
              overflow: hidden;

              img {
                max-width: 100%;
              }

              .temp {
                width: 97px;
              }
            }

            .content {
              height: 108px;
              flex: 1;
              display: flex;
              overflow: hidden;
              flex-direction: column;
              justify-content: space-between;

              .top {
                display: flex;
                flex-direction: column;

                .title {
                  color: #333;
                  font-size: 16px;
                  font-weight: bold;
                }

                .date {
                  color: #999;
                  font-size: 12px;
                  margin-top: 10px;
                }
              }

              .bottom {
                font-size: 14px;
                color: #666;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
                word-break: break-all;
              }
            }

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            &:hover {
              cursor: pointer;
              background-color: #fff;
            }
          }
        }
      }
    }

    .partner-part {
      justify-content: space-between;

      .container {
        max-width: 1200px;
        margin: 0 auto;

        .title {
          margin: 80px 0 40px;
          font-size: 36px;
          font-weight: bold;
        }

        .partner-content {
          margin-bottom: 80px;

          .item {
            img {
              width: 256px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: auto;

  .partner-part {
    background-color: #F8FDFB;
    overflow: hidden;

    .container {
      padding: 0;

      .title {
        text-align: center;
      }

      .partner-content {
        display: flex;
        justify-content: space-between;

        .item {
          display: flex;
          flex-flow: column;
          align-items: center;

          span {
            font-size: 14px;
            color: #333;
          }
        }
      }
    }
  }

  .modal-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000, $alpha: 0.3);
    align-items: center;
    justify-content: center;
    z-index: 10;

    .content {
      background-color: #fff;
      border-radius: 12px;
      text-align: center;
    }

    @include mediaPc {
      .content {
        width: 400px;
        padding: 30px 0;

        .hl-modal-title {
          white-space: nowrap;
          font-size: 20px;
          font-weight: bold;
        }

        .hl-modal-content {
          padding: 31px 63px 0;
          display: flex;
          justify-content: space-around;

          img {
            width: 115px;
          }

          .text {
            font-size: 12px;
            color: #999;
            text-align: center;
          }
        }

        img {
          width: 200px;
        }
      }
    }

    @include mediaMobile {
      .content {
        width: 80vw;
        padding: px2vw(20);

        .hl-modal-title {
          white-space: nowrap;
          font-size: 14px;
          font-weight: bold;
        }

        .hl-modal-content {
          margin-top: px2vw(31);
          display: flex;
          justify-content: space-around;

          img {
            width: px2vw(115);
          }

          .text {
            font-size: 12px;
            color: #999;
            text-align: center;
          }
        }
      }
    }
  }
}
