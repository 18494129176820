.terms-container {
  height: 100vh;
  overflow: auto;
  padding: 0 40px;

  .content {
    margin: 20px 0 20px;
    word-break: break-all;
    white-space: pre-wrap;
  }
}
