.posts-list-wrapper {
  background-color: #f8f8f8;
  @include mediaPc {
    padding-bottom: 66px;
    margin-top: 72px;
    h1 {
      color: #333;
      font-size: 20px;
      margin: 0 auto;
      width: 1200px;
      line-height: 66px;
      font-weight: bold;
    }
    .huiliu-pagination {
      padding: 20px;
    }
  }

  @include mediaMobile {
    margin-top: 61.78px;
    h1 {
      color: #333;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      padding: 20px 0;
      margin: 0;
    }
    .huiliu-pagination {
      padding: 20px 0;
      margin: 0;
    }
  }
  .list {
    background-color: #fff;
    @include mediaPc {
      width: 1200px;
      margin: 0 auto;
      .item {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 30px 20px;
        transition: all 0.2s linear;
        .cover {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 172px;
          height: 108px;
          border-radius: 4px;
          margin-right: 20px;
          background-color: #eee;
          overflow: hidden;

          img {
            max-width: 100%;
          }

          .temp {
            width: 97px;
          }
        }

        .content {
          height: 108px;
          flex: 1;
          display: flex;
          overflow: hidden;
          flex-direction: column;
          justify-content: space-between;

          .top {
            display: flex;
            flex-direction: column;

            .title {
              color: #333;
              font-size: 16px;
              font-weight: bold;
            }

            .date {
              color: #999;
              font-size: 12px;
              margin-top: 10px;
            }
          }

          .bottom {
            font-size: 14px;
            color: #666;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
            word-break: break-all;
          }
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &:hover {
          cursor: pointer;
          background-color: #fffaec;
        }
      }
    }
    @include mediaMobile {
      .item {
        display: flex;
        align-items: center;
        padding: px2vw(18) px2vw(12);
        flex-direction: row-reverse;

        .cover {
          display: flex;
          align-items: center;
          justify-content: center;
          width: px2vw(114);
          height: px2vw(88);
          overflow: hidden;
          border-radius: 4px;
          margin-left: px2vw(20);
          background-color: #eee;

          img {
            max-width: 100%;
          }

          .temp {
            width: px2vw(97);
          }
        }

        .content {
          height: px2vw(88);
          flex: 1;
          display: flex;
          overflow: hidden;
          flex-direction: column;
          justify-content: space-between;

          .top {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
              color: #333;
              font-size: 16px;
              font-weight: bold;
            }

            .date {
              color: #999;
              font-size: 12px;
            }
          }
        }

        &:not(:last-child) {
          border-bottom: 1px #eee solid;
        }
      }
    }
  }
}
