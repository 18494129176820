.welfare-container {
  .part {
    width: 100vw;
    position: relative;

    .part-img {
      width: 100vw;
      display: block;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &.part2 {

      .content {
        position: absolute;
        bottom: px2vw(40);
        top: initial;
        height: initial;
        // padding-top: px2vw(90);

        .welfare-content {
          display: none;
          flex-direction: column;
          align-items: center;

          img {
            width: px2vw(286);
            margin-bottom: px2vw(29);
          }

          .button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: px2vw(280);
            height: px2vw(45);
            font-size: 18px;
            font-weight: bold;
            border-radius: px2vw(23);

            &.disabled {
              opacity: 0.4;
            }
          }

          &.show {
            display: flex;
          }

          &#welfare-initial {
            .button {
              background-color: #ff3f1f;
              color: #fff;
            }
          }

          &#welfare-wait_create,
          &#welfare-wait_send {
            .button {
              background-color: #ffae20;
              color: #fff;
            }
          }

          &#welfare-wait_evaluation {
            .button {
              border: 1px solid #cccccc;
              color: #333;
            }
          }

          &#welfare-has_created_backflows {
            .button {
              background-color: #ff3f1f;
              color: #fff;
            }
          }

          &#welfare-has_shipped_backflows {
            .button {
              background-color: #ffae20;
              color: #fff;
            }
          }

          &#welfare-has_evaluated_backflows {
            .button {
              border: 1px solid #cccccc;
              color: #333;
            }
          }

          &#welfare-success {
            .button {
              border: 1px solid #ff3f1f;
              color: #ff3f1f;
            }
          }

          &#welfare-failure {
            .button {
              background: #eeeeee;
              color: #999999;
            }
          }
        }

        .welfare-count-down {
          .iconfont {
            font-weight: bold;
            margin-right: px2vw(4);
          }

          .count-down {
            margin-left: px2vw(4);
            letter-spacing: px2vw(2);
            color: #FF3F1F;
            font-weight: bold;
          }

          padding-top: px2vw(10);
          margin-bottom: px2vw(-10);
          align-items: center;
          font-size: px2vw(12);

          display: none;

          &.show {
            display: flex;
          }
        }
      }
    }

    &.part4 {
      .content {
        padding-top: px2vw(78);

        .rule {
          width: px2vw(313);
          display: flex;
          flex-direction: column;
          align-items: center;

          .rule-row {
            width: 100%;
            font-size: px2vw(15);
            color: #333333;

            &:not(:last-child) {
              margin-bottom: px2vw(8);
            }

            img {
              float: left;
              margin-right: px2vw(8);
              margin-top: px2vw(6);
              width: px2vw(11);
            }
          }
        }
      }
    }
  }

  .mask {
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.6);
    display: none;

    .guide {
      position: absolute;
      right: px2vw(10);
      top: px2vw(10);

      .iconfont,
      .text {
        position: absolute;
      }

      .arrow {
        width: px2vw(50);
        right: px2vw(10);
      }

      .text {
        display: flex;
        align-items: center;
        font-size: px2vw(14);
        white-space: nowrap;
        top: px2vw(20);
        right: px2vw(40);
        background-color: #fff;
        color: #333;
        padding: 0 px2vw(12);
        height: px2vw(60);
        border-radius: 50%;
      }
    }
  }
}
