.huiliu-pagination {
  display: flex;
  align-items: center;
  @include mediaPc {
    justify-content: flex-end;
    .view-all {
      width: 148px;
      height: 40px;
      border: 1px solid #979797;
      font-size: 14px;
      color: #999999;
      margin-right: 20px;
    }
  }
  @include mediaMobile {
    margin-top: 20px;
    justify-content: center;
    .hl-pagination {
      flex: 1;
    }
    .view-all {
      width: 70px;
      height: 30px;
      border: 1px solid #979797;
      font-size: 14px;
      color: #999999;
      margin-right: px2vw(10);
    }
  }
  .view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .hl-pagination {
    .page,
    .page a,
    .page-item a {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      &.prev,
      &.next,
      &.current {
        background-color: #fff;
        border-radius: 4px;
      }

      &.prev,
      &.next {
        a {
          color: #999999;
        }
      }

      &.page-item a {
        color: #333;
      }

      &.current {
        color: #44a949;
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    @include mediaPc {
      justify-content: flex-end;

      .page,
      .page a,
      .page-item a {
        width: 40px;
        height: 40px;
        transition: all 0.2s linear;

        &:hover {
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
          }

          background-color: #fff;
        }

        &.page-item {
          font-size: 16px;
        }

        &:not(:last-child) {
          margin-right: 4px;
        }
      }
    }

    @include mediaMobile {
      justify-content: center;
      padding: 0 px2vw(10);

      .page,
      .page a,
      .page-item a {
        max-width: 30px;
        flex: 1;
        aspect-ratio: 1;
        transition: all 0.2s linear;

        &:hover {
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
          }

          background-color: #fff;
        }

        &.page-item {
          font-size: 16px;
        }

        &:not(:last-child) {
          margin-right: px2vw(4);
        }
      }
    }
  }
}
