.rights-container {
  min-height: 100vh;
  background-image: url('https://huiliu-production-resources.huiliu.net/web/rights/banner-background.png');
  padding: 16px;
  min-height: 100vh;
  background-color: #fffcf4;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;

  .arrow {
    height: 11px;
  }

  .about-flow {
    background: #fff;
    box-shadow: 0px 3px 10px 0px rgba(182, 134, 11, 0.05);
    border-radius: 8px;
    margin-bottom: 12px;
    margin-top: 227px;

    img {
      width: 343px;
    }
  }

  .about-consult {
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    margin-top: 12px;
  }

  .about-consult-inner {
    position: relative;
    display: flex;

    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 2px solid #FFD149;
    }

    .text-section {
      margin-left: 14px;

      h1 {
        font-size: 18px;
        margin: 0;
        margin-top: 4px;
        margin-bottom: 1px;
      }

      p {
        font-size: 12px;
        color: #969798;
        margin: 0;
      }
    }

    .arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .about-huiliu {
    border-radius: 8px;
    background: #fff;
    padding: 16px;

    .title {
      position: relative;

      h1 {
        font-size: 18px;
        margin-bottom: 15px;
        position: relative;
      }

      img {
        width: 142px;
        position: absolute;
        top: -5px;
        left: 9px;
      }
    }

    .the-panel {
      .question {
        .question-header {
          padding: 12px 0;
          color: #303233;
          font-size: 14px;
          position: relative;
          font-weight: 300;

          .arrow {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
          }
        }

        ~.question {
          border-top: 1px solid #eee;
        }

        .question-body {
          font-size: 12px;
          padding: 0 0 12px;
        }
      }
    }
  }

  .bottom-text {
    width: 170px;
    display: block;
    margin: 32px auto 60px;
  }
}
