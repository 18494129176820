.download-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  background-image: url('~images/official_website/download/bg.png');
  background-position: 50%;
  background-size: contain;

  .wx-prompt {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);

    img {
      position: absolute;
      right: 28px;
      top: px2vw(14);
      width: px2vw(276);
      height: px2vw(107);
    }
  }

  .download-button {
    position: fixed;
    bottom: px2vw(109);
    left: 50%;
    transform: translateX(-50%);
    width: px2vw(221);
    height: px2vw(50);
    background-color: #FEBA07;
    color: #fff;
    font-size: px2vw(18);
    border-radius: px2vw(25);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
