.official-about-wrapper {
  overflow: hidden;

  .banner-top {
    background-color: #000;

    .banner {
      width: 100%;
    }

    @include mediaPc {
      margin-top: 66px;

      .banner-container {
        margin: 0 auto;
        min-width: 1200px;
        max-width: 1920px;
      }
    }

    @include mediaMobile {
      margin-top: 61.78px;

      .banner-container {
        width: 100%;
      }
    }
  }

  .company-intro {
    .intro-content {
      display: flex;

      .left-part {
        background-color: #fff;

        .company-info-part {
          display: flex;

          .company-name-part {
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            p {
              margin: 0
            }
          }
        }

        .company-intro-text {
          line-height: 28px;
          font-size: 14px;
          color: #333;
        }
      }

      .right-part {
        display: flex;
        flex-flow: column;
        justify-content: center;

        .tag-part {
          display: flex;

          img {
            object-fit: contain;
          }

          p:nth-of-type(2) {
            margin: 0;
            font-weight: bold;
          }
        }
      }
    }

    @include mediaPc {
      padding: 0 33px 40px;

      .intro-content {
        $height: 300px;

        .left-part {
          transform: translateY(-39px);
          width: 554px;
          height: $height;
          border-radius: 20px;
          padding: 30px 42px 0;
          box-shadow: 0px 20px 30px -8px #EAF2ED;
          margin-right: 46px;

          .company-info-part {
            .company-logo-img {
              height: 80px;
            }

            .company-name-part {
              height: 80px;

              img {
                width: 221px;
              }

              p {
                font-size: 16px;
              }
            }
          }

          .company-intro-text {
            margin: 25px 0 0;
          }
        }

        .right-part {
          height: $height;

          .tag-part {
            margin-bottom: 50px;

            img {
              width: 57px;
              margin-right: 18px;
            }

            p:nth-of-type(1) {
              margin-bottom: 12px;
              font-size: 16px;
            }

            p:nth-of-type(2) {
              margin: 0;
              font-size: 24px;
              font-weight: bold;
            }
          }
        }
      }
    }

    @include mediaMobile {
      padding: 0 px2vw(12);

      .intro-content {
        flex-flow: column;

        .left-part {
          transform: translateY(-33px);
          width: 100%;
          border-radius: px2vw(20);
          padding: 25px 20px;
          box-sizing: border-box;
          box-shadow: 0px 5px 12px -2px #DDE7DD;

          .company-info-part {
            $side: px2vw(60);

            .company-logo-img {
              height: $side;
            }

            .company-name-part {
              height: $side;

              img {
                width: px2vw(166);
              }

              p {
                font-size: 14px;
              }
            }
          }

          .company-intro-text {
            margin: 18px 0 0;
          }
        }

        .right-part {
          padding-bottom: 19px;

          .tag-part {
            margin-bottom: 20px;
            justify-content: center;

            &:last-child {
              .tag-text {
                padding-top: 20px;
                border-top: 1px solid #EEEEEE
              }
            }

            img {
              width: 27px;
              margin-right: 5px;
            }

            p:nth-of-type(1) {
              margin-bottom: 8px;
              font-size: 14px;
              text-align: center;
            }

            p:nth-of-type(2) {
              margin: 0;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .special {
    background-color: #F9F9F9;
    overflow: hidden;

    .special-carousel {

      .slick-slide {
        transition: transform 0.2s linear;

        &.slick-current {
          transform: scale(1.3);
        }

        .special-carousel-item {
          position: relative;

          img {
            border-radius: 15px;
            object-fit: cover;
          }

          .shadow {
            position: absolute;
            top: 10px;
            filter: blur(10px) brightness(80%) opacity(.8);
            transform: scale(0.95);
            z-index: -1;
          }
        }
      }


    }

    .special-info {
      display: flex;
      flex-flow: column;
      align-items: center;

      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 13px;
      }

      p {
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        margin: 0;
      }
    }

    @include mediaPc {
      .title-h2 {
        margin: 40px 0;
      }

      .special-carousel {
        width: 800px;
        margin: 0 auto;

        .slick-arrow {
          width: 48px;
          height: 48px;

          &.slick-prev {
            left: -55px;
          }

          &.slick-next {
            right: -55px;
          }

          &::before {
            font-size: 48px;
            color: #dddddd;
          }
        }

        .slick-slide {
          margin: 50px 80px;

          .special-carousel-item {
            img {
              width: 120px;
              height: 148px;
            }
          }
        }
      }

      .special-info {
        height: 220px;

        p {
          width: 378px;
        }
      }
    }

    @include mediaMobile {
      .title-h2 {
        margin: 30px 0;
      }

      .special-carousel {
        width: 100%;

        .slick-slide {
          margin: px2vw(30) px2vw(35) 60px;

          .special-carousel-item {
            img {
              width: px2vw(148);
              height: px2vw(180);
            }
          }
        }
      }

      .special-info {
        height: 190px;

        h3 {
          background-color: #F8D86A;
          height: 30px;
          padding: 0 px2vw(22);
          display: flex;
          align-items: center;
          border-radius: 15px;
          line-height: 1;
        }

        p {
          width: px2vw(311);
        }
      }
    }
  }
}
