@function px2vw($px) {
  $val: $px / 375 * 100;
  @return #{$val}vw;
}

@media screen and (min-width:993px) {
  .show-mobile {
    display: none !important;
  }
}

@media screen and (max-width:992px) {
  .show-pc {
    display: none !important;
  }
}

@mixin mediaPc {
  @media screen and (min-width:993px) {
    @content;
  }
}

@mixin mediaMobile {
  @media screen and (max-width:992px) {
    @content;
  }
}

.my-toast {
  position: relative;
  filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.4));

  div {
    display: none;
    position: absolute;
    padding: 4px 10px;
    border-radius: 8px;
    background-color: #fff;
    color: #777;
    white-space: nowrap;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -17px;
      transform: translateX(-50%);
      border: 10px solid #fff;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

}
